<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Edit category
			</div>
		</div>

		<div>
			<category-edit
				:categoryId="getCategoryId"
				:cancelRoute="getCancelRoute"
				:isCreate="false"
			/>
		</div>
	</div>
</template>

<script>
	import CategoryEdit  from '@/components/knowledgebase/edit/CategoryEdit';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Edit category'
		},
		components: {
			CategoryEdit
		},
		computed: {
			getCancelRoute () {
				return '/admin/knowledgebase/categories';
			},
			getCategoryId () {
				return this.$route.params.categoryId;
			}
		},
		created () {}
	};
</script>
